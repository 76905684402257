<template>
  <v-layout
  >

    <v-card
        class="mx-auto justify-center text-center"
        width="600"
        style="padding-top: 30px;"

        elevation-10
    >
      <v-card-title primary-title class="justify-center">
        <div>
          <h3 class="headline text--accent-2">{{ this.title }}</h3>
        </div>
      </v-card-title>


      <v-form
          autocomplete="off"
      >


        <v-text-field
            v-if="user_type == 'admin'"
            v-model="name"
            sm="12"
            md="4"
            label="Name"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-if="user_type != 'admin'"
            v-model="name"
            sm="12"
            md="4"
            label="Name"
            required
            outlined
            type="search"
            disabled
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <CustomSelect
            v-if="user_type == 'admin'"

            v-model="customer_name"
            v-bind:table="'customer'"
            v-bind:field_text="'name'"
            v-bind:field_value="'name'"
            v-bind:label="'Kunde'"
            v-bind:selected="customer_name"
            v-bind:extension="extension_custom_select_customer"

            style="
            display: table;
            margin: 0 auto;
            width: 350px;"
        >

        </CustomSelect>

        <v-select
            v-if="customer_name.toLowerCase() == 'oknoplast'"
            sm="12"
            md="4"
            v-model="marke"
            :items="marke_items"
            label="Marke"
            outlined
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"
        ></v-select>

        <v-text-field
            v-if="customer_name.toLowerCase() != 'oknoplast'"
            v-model="marke"
            sm="12"
            md="4"
            label="Marke"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>



        <v-text-field
            v-model="start_month"
            sm="12"
            md="4"
            label="Start Monat"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>

        <v-text-field
            v-model="start_year"
            sm="12"
            md="4"
            label="Start Jahr"
            required
            outlined
            type="search"
            autocomplete="off"
            style="
        display: table;
        margin: 0 auto;
        width: 350px;"

        ></v-text-field>


        <!--

        <DialogDate
            v-bind:label="'Projekt Start Datum'"
            v-bind:initial_date="start_date"
            v-bind:clearable="true"
            v-model="start_date"
            :key="start_date"

        >

        </DialogDate>

        -->
<!--
        <DialogDate
            v-bind:label="'Veröffentlichung'"
            v-bind:initial_date="start_date"
            v-bind:clearable="true"
            v-model="start_date"
        >

        </DialogDate>
-->


<!--

        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field

                style="
                display: table;
                margin: 0 auto;
                width: 350px;"

                v-model="computedDate"
                label="Start"

                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

-->



        <div
            style="padding: 10px;"
        ></div>

        <v-divider></v-divider>

        <div
            style="padding: 10px;"
        ></div>



        <div
            style="padding: 10px;"
        ></div>




        <!--
        </v-col>
        -->

        <v-row
            style="padding: 10px"
        >

          <v-col
              sm="10"
              md="10"
              class="text-right"
          >

            <v-btn
                color="red"
                elevation="2"
                outlined
                style="margin-right: 40px"
                @click="btnCancel"
            >Abbrechen</v-btn>


            <v-btn
                color="green"
                elevation="2"
                outlined
                @click="saveData"
            >Speichern</v-btn>


          </v-col>

        </v-row>

        <div
            style="padding: 10px;"
        ></div>


      </v-form>

      <v-spacer />


    </v-card>


    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>


  </v-layout>
</template>

<script>

 import CustomSelect from '@/components/CustomSelect'

export default {
  name: "FormProject",

  components:{
    CustomSelect,
  },

  data () {
    return {

      title: 'Projekt',
      mainview: 'ListProject',
      table: 'project',

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      modal: false,

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,

      user_type: '',

      extension_custom_select_customer: "SELECT * FROM customer ORDER BY name ASC",

      id: '',
      name: '',
      customer_name: '',

      start_timestamp: '',
      end_timestamp: '',

      start_month: '',
      start_year: '',

      marke: '',

      marke_items: [
        '',
        'Oknoplast',
        'Aluhaus',
        'WnD',
        'Catadi',
      ],

      start_date: '',
      end_date: '',

    }
  },

  created(){

    this.user_type = localStorage.typ
    this.customer_name = localStorage.username

    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id

      this.loadData()

    }

  },


  methods: {

    btnCancel(){

      this.$router.push({ name: this.mainview })

    },


    async loadData(){

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.name = result[0]["name"]
      this.customer_name = result[0]["customer_name"]

      this.marke = result[0]["marke"]

      this.start_month = result[0]["start_month"]
      this.start_year = result[0]["start_year"]


    },



    async saveData(){

      var statement

      if(this.id == ""){
        statement = "insert"
      }else{
        statement = "update"
      }

      // Einstellungen
      var param = {
        table: this.table,
        statement: statement,
        type: "",
        where: "true",
        selector: "id",
        value: this.id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }




      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {

        name: this.name,
        customer_name: this.customer_name,
        customer_id: "",

        start_month: this.start_month,
        start_year: this.start_year,

        marke: this.marke,

        baupr_url: "",
        baupr_text: "",

      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      this.snackbar_title = "Gespeichert!"
      this.snackbar_color = "green"
      this.snackbar = true

      if(statement == "insert"){
        this.id = result["value"].toString()
      }


    },


  },



}
</script>

<style scoped>

</style>
