var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',[_c('v-data-table',{staticClass:"elevation-7",attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"id","items-per-page":100,"search":_vm.search,"custom-filter":_vm.filterOnlyCapsText,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-options': [50, 100, 200, -1],
           'items-per-page-text':'pro Seite'
      }},scopedSlots:_vm._u([{key:"item.aäwg",fn:function(ref){
      var item = ref.item;
return [(item.aäwg == '')?_c('p',[_vm._v("k.A.")]):_c('p',[_vm._v(_vm._s(Number(item.aäwg).toLocaleString('de-DE').replace(",", "."))+" EUR")])]}},{key:"item.auflage",fn:function(ref){
      var item = ref.item;
return [_c('p',[_vm._v(_vm._s(Number(item.auflage).toLocaleString('de-DE').replace(",", ".")))])]}},{key:"item.note",fn:function(ref){
      var item = ref.item;
return [_c('p',[_vm._v(_vm._s(item.note.toString().substring(0, 15)))])]}},{key:"item.projekt",fn:function(ref){
      var item = ref.item;
return [(item.untertitel == "infobeleg" || item.untertitel == "Infobeleg")?_c('p',{staticStyle:{"color":"red"}},[_vm._v("INFOBELEG")]):_vm._e(),(item.untertitel == "werbung" || item.untertitel == "Werbung")?_c('p',{staticStyle:{"color":"red"}},[_vm._v("WERBUNG")]):_c('p',[_vm._v(_vm._s(item.projekt))])]}},{key:"item.weight",fn:function(ref){
      var item = ref.item;
return [(Number(item.weight) >= 0)?_c('p',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(item.weight))]):_c('p',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.weight))])]}},{key:"item.projectstart",fn:function(ref){
      var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(item.project_start_month)+" / "+_vm._s(item.project_start_year)+" ")])]}},{key:"item.erfassung",fn:function(ref){
      var item = ref.item;
return [_c('p',[_vm._v(" "+_vm._s(_vm.formatDate(item.erfassung))+" ")])]}},{key:"item.invoice",fn:function(ref){
      var item = ref.item;
return [(item.invoice == 1)?_c('p',{staticStyle:{"color":"green"}},[_c('v-icon',{staticStyle:{"margin-top":"5px"},attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1):_c('p',{staticStyle:{"color":"red"}},[_c('v-icon',{staticStyle:{"margin-top":"5px"},attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1)]}},{key:"item.invoice_payment_date",fn:function(ref){
      var item = ref.item;
return [(item.invoice_payment_date != '')?_c('p',{staticStyle:{"color":"green"}},[_c('v-icon',{staticStyle:{"margin-top":"5px"},attrs:{"color":"green"}},[_vm._v("mdi-check-circle")])],1):_c('p',{staticStyle:{"color":"red"}},[_c('v-icon',{staticStyle:{"margin-top":"5px"},attrs:{"color":"red"}},[_vm._v("mdi-close-circle")])],1)]}},{key:"item.typ",fn:function(ref){
      var item = ref.item;
return [(item.typ == 'buy')?_c('p',{staticStyle:{"color":"green"}},[_vm._v("Einkauf")]):_vm._e(),(item.typ == 'sell')?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Verkauf")]):_vm._e(),(item.typ == 'disposal')?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Verschrottung")]):_vm._e()]}},{key:"item.prio",fn:function(ref){
      var item = ref.item;
return [(item.prio == 1)?_c('p',{staticStyle:{"color":"green"}},[_vm._v("▓")]):_vm._e(),(item.prio == 2)?_c('p',{staticStyle:{"color":"orange"}},[_vm._v("▓")]):_vm._e(),(item.prio == 3)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("▓")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Suche / Filter","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.gattung",fn:function(ref){
      var item = ref.item;
return [(item.gattung == 'Zeitung, Regionalausgabe' || item.gattung == 'Zeitungen' || item.gattung == 'Tageszeitung' || item.gattung == 'Zeitung' )?_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_vm._v(" Tageszeitungen ")])]):_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.gattung)+" ")])])]}},{key:"item.show_belege",fn:function(ref){
      var item = ref.item;
return [(item.images == 'true')?_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},on:{"click":function($event){return _vm.showBeleg(item.id)}}},[_vm._v("mdi-information-outline")]),(item.newsletter == '1')?_c('v-icon',{staticStyle:{"color":"#CA0931","padding":"3px"}},[_vm._v("mdi-email-newsletter")]):_vm._e()],1)]):_vm._e()]}},{key:"item.belege",fn:function(ref){
      var item = ref.item;
return [(item.images == 'true')?_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"green"}},[_vm._v("mdi-check-bold")])],1)]):_vm._e(),(item.images == 'false')?_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"red"}},[_vm._v("mdi-close-thick")])],1)]):_vm._e()]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item.id, item.company)}}},[_vm._v("mdi-delete-outline")])],1)])]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item.id, item.company)}}},[_vm._v("mdi-delete-outline")]),_c('v-icon',{staticStyle:{"padding":"3px"},on:{"click":function($event){return _vm.editEntry(item.id)}}},[_vm._v("mdi-pencil-outline")])],1)])]}},{key:"item.edit_login",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"red"},on:{"click":function($event){return _vm.showDeleteDialog(item.id, item.company)}}},[_vm._v("mdi-delete-outline")]),_c('v-icon',{staticStyle:{"padding":"3px"},on:{"click":function($event){return _vm.editEntry(item.id)}}},[_vm._v("mdi-pencil-outline")]),_vm._v(" "),_c('v-icon',{staticStyle:{"padding":"3px"},on:{"click":function($event){return _vm.testLogin(item.id)}}},[_vm._v("mdi-login-variant")])],1)])]}},{key:"item.select_add",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"pink"},on:{"click":function($event){return _vm.add2Tour(item.id)}}},[_vm._v("mdi-plus-box")])],1)])]}},{key:"item.select_delete",fn:function(ref){
      var item = ref.item;
return [_c('v-row',[_c('div',{staticStyle:{"display":"inline","white-space":"nowrap"}},[_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"gray"},on:{"click":function($event){return _vm.posUp(item.id)}}},[_vm._v("mdi-arrow-up-drop-circle")]),_vm._v(" "),_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"gray"},on:{"click":function($event){return _vm.posDown(item.id)}}},[_vm._v("mdi-arrow-down-drop-circle")]),_vm._v(" "),_c('v-icon',{staticStyle:{"padding":"3px"},attrs:{"color":"#383083"},on:{"click":function($event){return _vm.delete2Tour(item.id)}}},[_vm._v("mdi-minus-box")])],1)])]}}])},[_vm._v(" Zeitung, Regionalausgabe ")])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Wirklich Löschen")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_vm._v(" Wollen Sie den Datensatz "),_c('b',[_vm._v(_vm._s(_vm.dialog_text))]),_vm._v(" wirklich löschen? ")]),_c('v-text-field',{attrs:{"type":"password","placeholder":"Passwort"},model:{value:(_vm.dialog_password),callback:function ($$v) {_vm.dialog_password=$$v},expression:"dialog_password"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteEntry}},[_vm._v(" Löschen ")])],1)],1)],1)],1),_c('v-fab-transition',[(_vm.addbutton == true)?_c('v-btn',{attrs:{"color":"pink","dark":"","fixed":"","bottom":"","right":"","fab":""},on:{"click":_vm.openAddView}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_title)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }