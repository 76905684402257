<template>
<div

>
  <div
  style="
  margin-left: 50px;
  margin-top: 30px;
  "
  >

    <!--{{ $t("message") }}-->

    <h2>{{ $t("welcome") }},</h2>
  </div>


    <div

       v-if="customer_name.toLowerCase() == 'oknoplast'"

       style="width: 100%;
       float: left;
       "
    >

        <CardCompareLastYear
                v-bind:title="$t('anzahlbelege').toString()"
                v-bind:selector="'count(*)'"
                v-bind:chartId="'1'"

        ></CardCompareLastYear>

        <CardCompareLastYear
                v-bind:title="$t('umfang').toString()"
                v-bind:selector="'ROUND(sum(umfang), 2)'"
                v-bind:chartId="'2'"

        ></CardCompareLastYear>

        <CardCompareLastYear
                v-bind:title="$t('aquivalenzwert').toString()"
                v-bind:selector="'sum(aäwg)'"
                v-bind:chartId="'4'"

        ></CardCompareLastYear>



      <!--
        <CardCompareLastYear
                v-bind:title="$t('auflage').toString()"
                v-bind:selector="'auflage'"
                v-bind:chartId="'3'"

        ></CardCompareLastYear>
    -->


      <CardCompareCurrentB2X
          v-if="customer_name.toLowerCase() == 'oknoplast'"
          v-bind:title="'B2B & B2C Vergleich (Aktuelles Jahr / Print)'"
          v-bind:selector="'count(*)'"
          v-bind:chartId="'5'"
      ></CardCompareCurrentB2X>


    </div>



<div
style="width: 100%;
       float: left;
"
>



    <!-- ToDo: muss noch dynamisch gemacht werden -->
    <!-- ToDo: Benötigt noch Tag -->

  <CardYearSummary
      :year="'2024'"

  ></CardYearSummary>

  <CardYearSummary
      :year="'2023'"

    ></CardYearSummary>

  <CardYearSummary
      :year="'2022'"

  ></CardYearSummary>

  <CardYearSummary
      :year="'2021'"

  ></CardYearSummary>
</div>
<div
style="width: 50%;

display: inline-block;
"
>
  <!--

  <CardChart></CardChart>

  -->

</div>

</div>
</template>

<script>

import CardCompareLastYear from '../components/CardCompareLastYear';
import CardYearSummary from '../components/CardYearSummary';
import CardCompareCurrentB2X from "@/components/CardCompareCurrentB2X.vue";
// import CardChart from '../components/CardChart';

export default {
  name: "Dashboard",

  components: {
    CardCompareCurrentB2X,
    CardYearSummary,
    CardCompareLastYear,
  //  CardChart,
  },



    data() {
        return {

          customer_name: '',

      }

    },

  created(){

      this.customer_name = localStorage.customer_name


    if(localStorage.typ == "admin"){
      this.$router.push({ name: 'ListCustomer' })
    }


    if(localStorage.username == "sommer" ){
      this.$router.push({ name: 'Infoclip' })
    }


    if(localStorage.username == "bona" ){
      this.$router.push({ name: 'Infoclip' })
    }





  }



}
</script>

<style scoped>

</style>
