<template>

    <v-card

    style="
        margin: 20px;
        width: 500px;
        height: 300px;
        float: left;

    "
    >

        <v-card-title>{{title}}</v-card-title>

        <div
            v-if="renderComponent"
        >

            <Bar :chart-options="chartOptions"
                 :chart-data="chartData"
                 :chart-id="chartId"
                 :dataset-id-key="datasetIdKey"
                 :plugins="plugins"
                 :css-classes="cssClasses"
                 :styles="styles"
                 :width="width"
                 :height="height"

                 style="
                 margin-left: 20px;
                 width: 450px"
            />

        </div>
    </v-card>




</template>

<script>

import {Bar} from 'vue-chartjs/legacy'

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export default {
    name: "CardCompareCurrentB2X",

    components: { Bar },



    created() {

      this.loadData()

    },


    methods:{

        async loadData(){

// ToDo: Dynamisch
           // var year_start_formated1 = "January 2024"
           //  var year_end_formated1 = "December 2024"

           // var year_start_formated2 = "January 2024"
           // var year_end_formated2 = "December 2024"



            var dates = [];

                    dates.push("Jan");
                    dates.push("Feb");
                    dates.push("März");
                    dates.push("April");
                    dates.push("Mai");
                    dates.push("Jun");
                    dates.push("Jul");
                    dates.push("Aug");
                    dates.push("Sept");
                    dates.push("Okt");
                    dates.push("Nov");
                    dates.push("Dez");


            var array_values1 = [] // Vorjahr
            var array_values2 = [] // Aktuelles Jahr


            for (var i1 = 0; i1 < 12; i1++) {
                array_values1.push("0")
            }
            for (var i2 = 0; i2 < 12; i2++) {
                array_values2.push("0")
            }


            var sub_array = []

            sub_array.push({
                label: 'B2B',
                backgroundColor: "#808080",
                borderColor: "#808080",
                borderWidth: 1,
                data: array_values1,
            })

            sub_array.push({
                label: 'B2C',
                backgroundColor: "#ca0931",
                borderColor: "#ca0931",
                borderWidth: 1,
                data: array_values2
            })


            this.chartData["datasets"] = sub_array
            this.chartData["labels"] = dates



            var extension = ""

            if (localStorage.product == "Alle" || localStorage.product == ""){
                // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"
                extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00' GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;"
            }else{

                if (localStorage.product == "Oknoplast"){

                  extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND ((lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(projekt) NOT LIKE '%catati%') OR lower(marke) LIKE '%oknoplast%') AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00' GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;";
                  // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(projekt) NOT LIKE '%catati%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"

                }else{

                  extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND ((lower(projekt) LIKE '%" + localStorage.marke + "%' OR lower(marke) LIKE '%" + localStorage.marke + "%') AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00') GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;";
                  // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%'  OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%') AND (lower(untertitel) NOT LIKE '%infobeleg%' AND  lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"

                }

            }






            // Einstellungen
            var param = {
                table: "releaselists",
                statement: "custom",
                type: "",
                where: "false",
                selector: "",
                value: "",
                short: "false", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: extension,
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            var data = {
            }

            // param.extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00' GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;"


            var result = await this.$func.queryAPI(param, data)


          console.log("result B2B")
          console.log(param.extension)
          console.log(result)


            for (let i1 = 0; i1 < result.length; i1++) {

                    this.chartData["datasets"][0]["data"][i1] = result[i1]["total_count"]

            }


// B2C

          if (localStorage.product == "Alle" || localStorage.product == ""){
            // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"
            extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) NOT LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00' GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;"
          }else{

            if (localStorage.product == "Oknoplast"){

              extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND ((lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(projekt) NOT LIKE '%catati%') OR lower(marke) LIKE '%oknoplast%') AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) NOT LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00' GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;";
              // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(projekt) NOT LIKE '%catati%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"

            }else{

              extension = "SELECT m.month_number AS month, COALESCE(r.total_count, 0) AS total_count FROM (SELECT 1 AS month_number UNION ALL SELECT 2 UNION ALL SELECT 3 UNION ALL SELECT 4 UNION ALL SELECT 5 UNION ALL SELECT 6 UNION ALL SELECT 7 UNION ALL SELECT 8 UNION ALL SELECT 9 UNION ALL SELECT 10 UNION ALL SELECT 11 UNION ALL SELECT 12) m LEFT JOIN (SELECT MONTH(date_timestamp) AS month, COUNT(*) AS total_count FROM releaselists WHERE gattung != 'Online-Medien' AND ((lower(projekt) LIKE '%" + localStorage.marke + "%' OR lower(marke) LIKE '%" + localStorage.marke + "%') AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) NOT LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%' AND customer_id = '4' AND date_timestamp BETWEEN '2024-01-01 00:00:00' AND '2024-12-31 23:59:00') GROUP BY MONTH(date_timestamp)) r ON m.month_number = r.month ORDER BY m.month_number;";
              // extension = "SELECT " + this.selector + ", MONTH(date_timestamp)  FROM releaselists WHERE gattung != 'Online-Medien' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%'  OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%') AND (lower(untertitel) NOT LIKE '%infobeleg%' AND  lower(untertitel) NOT LIKE '%werbung%' AND lower(gattung) LIKE '%fach%' AND lower(gattung) NOT LIKE '%online%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated1, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated1, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' GROUP BY MONTH(date_timestamp)"

            }

          }



            // Einstellungen
            param = {
                table: "releaselists",
                statement: "custom",
                type: "",
                where: "false",
                selector: "",
                value: "",
                short: "false", // Übermittlung nur von id
                // filter: "false",
                // filterfield: "",
                // filtervalue: "",
                // operator: "true",
                // Filter noch hinzufügen
                extension: extension,
            }

            // Daten -> Werden nur bei Insert oder Update gesendet
            data = {
            }


            result = await this.$func.queryAPI(param, data)

          console.log("result B2C")
          console.log(param.extension)
          console.log(result)

            for (let i1 = 0; i1 < result.length; i1++) {

                this.chartData["datasets"][1]["data"][i1] = result[i1]["total_count"]

            }




            // Wait for the change to get flushed to the DOM
            await this.$nextTick();

            // Add the component back in
            this.renderComponent = true;

            this.overlay = false






        }


    },


    data() {
        return {

            renderComponent: false,


            /*
            chartData: {
                labels: ['1', '2', '3', '4'],
                datasets: [
                    {
                        backgroundColor: ['#CA0931'],
                        data: [20, 20, 40, 20]
                    },

                    {
                        backgroundColor: ['#CA0931'],
                        data: [40, 60, 10, 30]
                    }

                    ],

            },

            */
            chartData: {
                labels: [],
                datasets: [],

            },

            chartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }


        }
    },


    props: {

        title: {
            type: String,
            default: 'B2B & B2C Vergleich'
        },

        selector: {
            type: String,
            default: 'ROUND(sum(umfang), 2)'
        },


        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 250
        },
        height: {
            type: Number,
            default: 200
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        }
    },

}
</script>

<style scoped>

</style>
