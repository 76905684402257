<template>
<div
style="margin: 30px;"
>

  <div
  style="
  width: 100%;
  max-width: 1200px;
  display: flex;

  "
  >
    <div
        style="width: 50%;"
    >
  <h2>{{ $t("releaselist") }} {{kunde}} {{title}}</h2>
    </div>
    <div
    style="
    text-align: right;
    width: 50%"
    >
      <!--
    <v-btn
        class="mx-2"
        fab
        dark
        color="#CA0931"
        @click="back2ReleaseList"
    >
      <v-icon
      >
        mdi-close
      </v-icon>

    </v-btn>
    -->

      <v-btn
          outlined
          class="mx-2"
          color="#CA0931"
          @click="back2ReleaseList"
      >
        {{ $t("back") }}
      </v-btn>


    </div>
  </div>

  <div
  style="margin: 15px"
  ></div>
  <div
  style="
  max-width: 1350px;
  border-bottom: 1px solid black;
  "
  >

    <table
        style="
        text-align: left;
        font-weight: bold;
        "
    >
      <td
          style="
      width: 450px;
      "
      >
        {{ $t("titel") }}
      </td>

      <td
          style="
      width: 150px;
      "
      >
        {{ $t("auflagevisits2") }}
      </td>

        <td
                v-if="show_aäwg == true"

                style="
      width: 150px;
      "
        >
            {{ $t("aquivalenzwert") }}
        </td>

      <td
          style="
      width: 150px;
      "
      >
        Projektstart
      </td>

      <td
          style="
      width: 250px;
      "
      >
        {{ $t("produkteprojekte") }}
      </td>

      <td
          style="
      width: 150px;
      "
      >
        {{ $t("umfang") }}
      </td>

      <td
          style="
      width: 150px;
      "
      >
        {{ $t("termin") }}
      </td>

      <td
          style="
      width: 80px;
      "
      >
          {{$t("beleg")}}
      </td>


    </table>

  </div>

  <div
      style="margin: 15px"
  ></div>

  <div
      v-for="gattung in gattungen"
      :key="gattung.id"

  >
    <div
    style="
    margin-top: 15px;
    margin-bottom: 15px;
    "
    >
   <h3>{{gattung.name}}</h3>
    </div>

  <table
        v-for="beleg in belege.filter(obj => obj.gattung == gattung.name)"
        :key="beleg.id"

        style="

        text-align: left;
        "
  >
      <td
      style="
      width: 350px;
      "
      >
        {{beleg.medium}}
      </td>

      <td
              v-if="beleg.auflage == '0'"
      style="
      text-align: center;
      width: 150px;
      "
      >
        k.A.
      </td>
      <td
              v-else
              style="
      text-align: center;
      width: 150px;
      "
      >
          {{Number(beleg.auflage).toLocaleString('de-DE')}}
      </td>



<div v-if="show_aäwg == true">
      <td
              v-if="beleg.aäwg == ''"
              style="
      text-align: center;
      width: 150px;
      "
      >
          k.A.
      </td>
      <td
              v-else
              style="
      text-align: center;
      width: 150px;
      "
      >
          {{Number(beleg.aäwg).toLocaleString('de-DE') }} EUR
      </td>
</div>

    <td
        style="
        text-align: center;
      width: 150px;
      "
    >
      {{beleg.project_start_month}} / {{beleg.project_start_year}}
    </td>


      <td
      style="
      width: 250px;
      "
      >
        {{beleg.projekt}}
      </td>

      <td
          style="
      width: 150px;
      "
      >
        {{Number(beleg.umfang.replace(",", ".")).toFixed(2)}}
      </td>

    <!-- 150px -->
    <td
          style="
      width: 150px;
      "
      >
        {{beleg.edatum}}
      </td>

    <td
      style="
      text-align: right;
      width: 80px;
      "
    >
      <v-row
      >
        <div
     style="
     width: 20px;
     margin-right: 4px;
"
     >
        <v-icon v-if="beleg.newsletter == '1'"  style="color: #CA0931;">mdi-email-newsletter</v-icon>
        </div>




        <div
            v-if="beleg.url != ''"
        >
          <v-icon
              @click="openURL(beleg.url)"
          >
            mdi-web
          </v-icon>
        </div>

        <div
      v-if="beleg.images == 'true'"
      >
      <v-icon
          @click="showBeleg(beleg.id)"
      >
        mdi-information-outline
      </v-icon>
      </div>




      </v-row>


    </td>


    </table>


    <table


        style="
        background-color: lightgray;
        text-align: left;
        margin-top: 10px;
        "
    >
      <td
          style="
      width: 400px;
      "
      >
      <b>{{$t("zwischensumme")}}</b>
      </td>

      <td
          style="
      width: 150px;
      "
      >
      <b>{{Number(gattung.auflage).toLocaleString('de-DE')}}**</b>
      </td>


        <td
                v-if="show_aäwg == true"
                style="
      width: 150px;
      "
        >
            {{gattung.aäwg.toLocaleString('de-DE')}} EUR
        </td>

      <td
          style="
        text-align: center;
      width: 150px;
      "
      >

      </td>



      <!-- Belege -->
      <td
          style="
      width: 250px;
      "
      >
        <b>{{gattung.belege.toLocaleString('de-DE')}}</b>
      </td>

      <!-- Umfang -->
      <td
          style="
      width: 150px;
      "
      >
        <b>{{gattung.umfang.toFixed(2)}}</b>
      </td>


      <td
          style="
      width: 150px;
      "
      >

      </td>


      <td
      style="
      width: 50px;
      "
      >
      </td>




    </table>




  </div>
<div
style="margin: 20px"
></div>
  <table
  style="text-align: left"
  >
    <td
    style="width: 450px;
           text-decoration-line: underline;
    "
    ><b>{{ $t("summe") }}</b></td>
    <td
    style="
    text-decoration-line: underline;
    width: 150px"
    ><b>Print</b></td>
    <td
    style="
    width: 100px;
    text-decoration-line: underline;"
    ><b>Online</b></td>

    <td
        style="
    width: 100px;
    text-decoration-line: underline;"
    ><b>Newsletter</b></td>


  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("belege") }}</td>
    <td
        style="width: 150px"
    >{{total_print_belege}}</td>
    <td
        style="width: 100px"
    >{{total_online_belege}}</td>


    <td
        style="width: 100px"
    >{{total_newsletter_belege}}</td>



  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >Auflage / Visits**</td>
    <td
        style="width: 150px"
    >{{total_print_auflage.toLocaleString('de-DE')}}</td>
    <td
        style="width: 100px"
    >{{total_online_auflage.toLocaleString('de-DE')}}</td>


  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("reichweite") }}</td>
    <td
        style="width: 150px"
    >{{ total_print_reichweite }}</td>
    <td
        style="width: 100px"
    >k.A.</td>


  </table>
  <!--
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >Umfang ZS-Format</td>
    <td
        style="width: 150px"
    >{{total_print_umfang_zs}}</td>
    <td
        style="width: 100px"
    ></td>


  </table>
  -->
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("umfang") }}</td>
    <td
        style="width: 150px"
    >{{total_print_umfang_tz.toLocaleString('de-DE').replace(",", ".")}}</td>
    <td
        style="width: 100px"
    >{{total_online_umfang_tz.toLocaleString('de-DE').replace(",", ".") }}</td>


  </table>

    <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("aquivalenzwert") }}</td>
    <td
        style="width: 150px"
    >{{total_print_aäwg.toLocaleString('de-DE').replace(",", ".")}} EUR</td>
    <td
        style="width: 100px"
    ></td>


  </table>


  <div
      style="margin: 50px"
  ></div>
  <b>{{ $t("kumuliertseit") }} {{year_start}} {{ $t("bis") }} {{year_end}}</b>

  <div
      style="margin: 15px"
  ></div>

  <table
      style="text-align: left"
  >
    <td
        style="width: 450px;
           text-decoration-line: underline;
    "
    ><b>Summe</b></td>
    <td
        style="
    text-decoration-line: underline;
    width: 150px"
    ><b>Print</b></td>
    <td
        style="
    width: 100px;
    text-decoration-line: underline;"
    ><b>Online</b></td>

    <td
        style="
    width: 100px;
    text-decoration-line: underline;"
    ><b>Newsletter</b></td>


  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("belege") }}</td>
    <td
        style="width: 150px"
    >{{kumuliert_print_belege}}</td>
    <td
        style="width: 100px"
    >{{kumuliert_online_belege}}</td>
    <td
        style="width: 100px"
    >{{kumuliert_newsletter_belege}}</td>

  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("auflagevisits2") }}</td>
    <td
        style="width: 150px"
    >{{kumuliert_print_auflage.toLocaleString('de-DE').replace(",", ".")}}</td>
    <td
        style="width: 100px"
    >{{kumuliert_online_auflage.toLocaleString('de-DE').replace(",", ".")}}</td>


  </table>
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("reichweite") }}</td>
    <td
        style="width: 150px"
    >{{kumuliert_print_reichweite}}</td>
    <td
        style="width: 100px"
    >k.A.</td>


  </table>
  <!--
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >Umfang ZS-Format</td>
    <td
        style="width: 150px"
    >{{kumuliert_print_umfang_zs}}</td>
    <td
        style="width: 100px"
    ></td>


  </table>
  -->
  <table
      style="text-align: left"
  >
    <td
        style="width: 450px"
    >{{ $t("umfang") }}<!-- TZ-Format--></td>
    <td
        style="width: 150px"
    >{{kumuliert_print_umfang_tz.toLocaleString('de-DE').replace(",", ".")}}</td>
    <td
        style="width: 100px"
    >{{kumuliert_online_umfang_tz.toLocaleString('de-DE').replace(",", ".")}}</td>


  </table>


    <table
            style="text-align: left"
    >
        <td
                style="width: 450px"
        >{{ $t("aquivalenzwert") }}<!-- TZ-Format--></td>
        <td
                style="width: 150px"
        >{{kumuliert_print_aäwg.toLocaleString('de-DE').replace(",", ".")}} EUR</td>
        <td
                style="width: 100px"
        ></td>


    </table>





  <div
      style="margin: 50px"
  ></div>



  <div>
    <h5>
    * Die durchschnittliche Reichweite gibt an, wieviele Personen mit dem aufgeführten Medium Kontakt hatten.<br>
    Für die Berechnung wird die Auflage mit dem faktor 2.5 multipliziert<br>
    Die Angabe beruht auf dem Medienbeobachter Infopaq.<br>
    ** Die Angabe der Online Visits kann nur bei IVW-geprüften Seiten gemacht werden.<br>
    Die durch den Pressedienst erzielten Veröffentlichungen sind mit PD/... gekennzeichnet.<br>
    </h5>
  </div>


  <v-overlay
  v-if="overlay"
  @click="overlay = false"
  style="
  background-color: green;
        height: 100%;

        "
  >
<div
    style="
         height: 100%;
         width: 100%;
        "
>
    <img


        :src="image_url"
        style="
        max-height: 70%;
        width: auto;
        "
    >
</div>
  </v-overlay>


</div>
</template>

<script>

import Vue from "vue";

export default {
  name: "DetailReleaseList",

  data: () => ({
    title: '',
    kunde: '',
    id: '',
    gattungen: [],
    projekte: [],
    belege: [],

    marke: '',

    year_start: '',
    year_end: '',

    customer_name: localStorage.customer_name,

    kumuliert_print_belege: 0,
    kumuliert_print_auflage: 0,
    kumuliert_print_reichweite: 0,
    kumuliert_print_umfang_zs: 0,
    kumuliert_print_umfang_tz: 0,
    kumuliert_print_aäwg: 0,

    kumuliert_online_belege: 0,
    kumuliert_online_auflage: 0,
    kumuliert_online_reichweite: 0,
    kumuliert_online_umfang_zs: 0,
    kumuliert_online_umfang_tz: 0,

    kumuliert_newsletter_belege: 0,

    total_print_belege: 0,
    total_print_umfang_zs: 0,
    total_print_umfang_tz: 0,
    total_print_auflage: 0,
    total_print_aäwg: 0,
    total_print_reichweite: 0,

    total_online_belege: 0,
    total_online_umfang_zs: 0,
    total_online_umfang_tz: 0,
    total_online_auflage: 0,
    total_online_reichweite: 0,

    total_newsletter_belege: 0,

    visibility: false,

    show_aäwg: false,

    overlay: false,
    image_url: [],

  }),

  async created(){


    if(this.$route.params.id != undefined){
      this.id = this.$route.params.id
      this.title = this.translateMonth(this.$moment(this.id, "MM.YYYY").format("MMMM")) + " " + this.$moment(this.id, "MM.YYYY").format("YYYY")

      if (localStorage.marke == "Alle" || localStorage.marke == ""){
        this.kunde = this.customer_name
      }else{
        this.kunde = localStorage.marke
      }

      if(this.customer_name.toLowerCase() == "oknoplast"){
        this.show_aäwg = true
        this.kunde = this.kunde.toUpperCase()
      }

      // Fix für WnD Scheibweise Titel
      if(this.kunde.toUpperCase() == "WND"){
          this.kunde = "WnD"
      }



      await this.loadData()

      await this.Accumulation()

    }else{
      this.$router.push({ path: "/ReleaseLists" })
    }



  },



  methods:{

    back2ReleaseList(){

      this.$router.push({ path: "/ReleaseLists" })

    },


    openURL(url){
      console.log("openURL")

      if(url.substring(0, 3) == "www"){
        window.open("http://" + url, '_blank');
      }else{
        window.open(url, '_blank');
      }

    },



    async showBeleg(id){


      // Einstellungen
      var param = {
        table: "images",
        statement: "select",
        type: "list",
        where: "true",
        selector: "releaselists_id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      console.log("image")
      console.log(id)
      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      if(result[0]["type"] == "pdf"){
        window.open(Vue.prototype.$api_upload + result[0]["name"])
        return
      }

      // this.image_url = Vue.prototype.$api_upload + result[0]["name"]

      this.image_url = []

      for(var i = 0; i < result.length; i++){
        this.image_url.push(Vue.prototype.$api_upload + result[i]["name"])
      }

      const preview = this.$imagePreview({
        initIndex:0,
        isEnableBlurBackground: false,
        isEnableDownloadImage: false,
        images: this.image_url,

      })

      console.log(preview)

      // this.overlay = true

    },


    async loadData(){

      var extension = ""

      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp ASC"
      }else{

        if (localStorage.product == "Oknoplast"){
          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) NOT LIKE '%catadi%' AND lower(projekt) NOT LIKE '%wnd%' AND lower(projekt) NOT LIKE '%aluhaus%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%' AND lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%')) AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp ASC"
        }else{
          extension = "SELECT * FROM releaselists WHERE kunde='" + localStorage.customer_name + "' AND (lower(projekt) LIKE '%" + localStorage.product.toString().toLowerCase()  + "%' OR lower(untertitel) LIKE '%" + localStorage.product.toString().toLowerCase() + "%' OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' AND lower(untertitel) NOT LIKE '%infobeleg%' AND lower(untertitel) NOT LIKE '%werbung%') AND edatum LIKE '%" + this.id + "%' ORDER BY date_timestamp ASC"
        }

      }


      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "custom",
        type: "detail",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }


      console.log("extension")
      console.log(extension)



      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)


      console.log("result - SUMME")
      console.log(result)



      // Karte sichbar wenn Daten vorhanden
      if(result.length != 0){
        this.visibility = true
      }

      var objIndex

      // console.log(param.extension)
      // console.log(result)

      for (var i1 = 0; i1 < result.length; i1++) {





        if (result[i1]["gattung"] == "Online-Medien"){

          if (result[i1]["newsletter"] == "1"){
            this.total_newsletter_belege = this.total_newsletter_belege + 1;
          }

        this.total_online_belege = this.total_online_belege + 1;
        }else{
        this.total_print_belege = this.total_print_belege + 1;
        }


        /*
           0: "Zeitung"
           1-1: "Tageszeitungen"
           Tageszeitung
           1: "Zeitung, Regionalausgabe"
           2: "Anzeigenblätter"
           3: "Publikumszeitschrift"
           4: "Fachzeitschrift"
           5: "Online-Medien"
        */

        var gattung = result[i1]["gattung"]


          if (gattung == "Zeitung" || gattung == "Tageszeitungen" || gattung == "Tageszeitung" || gattung == "Zeitung, Regionalausgabe"){
          gattung = "Tageszeitungen"
          }

          if(gattung == "Anzeigen-/Offertenblätter"){
            gattung = "Anzeigenblätter"
          }




          if (!this.gattungen.some(el => el.name === gattung)){
             this.gattungen.push({id: i1, name: gattung, auflage: 0, belege: 0, umfang: 0, aäwg: 0})
          }

          if (!this.projekte.some(el => el.name === result[i1]["projekt"])){
            this.projekte.push({ id: i1, name: result[i1]["projekt"] })
          }


          if(result[i1]["auflage"] == ""){
            result[i1]["auflage"] = 0
          }

          this.belege.push({ id: result[i1]["id"], gattung: gattung, medium: result[i1]["medium"], auflage: result[i1]["auflage"], newsletter: result[i1]["newsletter"], aäwg: result[i1]["aäwg"], project_start_month: result[i1]["project_start_month"], project_start_year: result[i1]["project_start_year"], projekt: result[i1]["projekt"], umfang: result[i1]["umfang"], edatum: result[i1]["edatum"], images: result[i1]["images"], url: result[i1]["url"]})



        //Find index of specific object using findIndex method.
        objIndex = this.gattungen.findIndex((obj => obj.name == gattung));

        console.log(gattung)
        console.log(objIndex)

        this.gattungen[objIndex].belege = this.gattungen[objIndex].belege + 1


        if(!isNaN(Number(result[i1]["umfang"].replace(",",".")))){

          this.total_umfang = this.total_umfang + Number(result[i1]["umfang"].replace(",","."));

          this.gattungen[objIndex].umfang = this.gattungen[objIndex].umfang + Number(result[i1]["umfang"].replace(",","."))
        }
        if(!isNaN(Number(result[i1]["auflage"]))){

          if (result[i1]["gattung"] == "Online-Medien") {
            this.total_online_auflage = this.total_online_auflage + Number(result[i1]["auflage"]);
          }else{
            this.total_print_auflage = this.total_print_auflage + Number(result[i1]["auflage"]);
          }

          this.gattungen[objIndex].auflage = this.gattungen[objIndex].auflage + Number(result[i1]["auflage"])
        }
        if(!isNaN(Number(result[i1]["reichweite"]))){

          /*
          if (result[i1]["gattung"] == "Online-Medien") {
            this.total_online_reichweite = this.total_online_reichweite + Number(result[i1]["reichweite"]);
          }else{
            this.total_print_reichweite = this.total_print_reichweite + Number(result[i1]["reichweite"]);
          }
          */

        }

          if(!isNaN(Number(result[i1]["aäwg"]))){

              if (result[i1]["aäwg"] != "Online-Medien") {
                this.total_print_aäwg = this.total_print_aäwg + Number(result[i1]["aäwg"])

                  this.gattungen[objIndex].aäwg = this.gattungen[objIndex].aäwg + Number(result[i1]["aäwg"])

              }

          }



      }


      this.total_print_reichweite = Number(this.total_print_auflage) * 2.5
      this.total_print_reichweite = Number(this.total_print_reichweite).toFixed(0)
      this.total_print_reichweite = Number(this.total_print_reichweite).toLocaleString("de-DE").replace(",", ".")


      console.log("this.belege")
      console.log(this.belege)


      // Gattungen werden sortiert

      // console.log("Gattungen")
      // console.log(this.gattungen.sort(this.compare))

      var fromIndex = ""

      for (var i99 = 0; i99 < this.gattungen.length; i99++) {

        if(this.gattungen[i99]["name"] == "Tageszeitungen")
        fromIndex = i99

      }


      if(fromIndex != ""){

        var element = this.gattungen[fromIndex];
        this.gattungen.splice(fromIndex, 1);
        this.gattungen.splice(0, 0, element);

      }



      // this.total_umfang = Math.round(this.total_umfang)
      /*
            console.log("total_belege: " + this.total_belege)
            console.log("total_umfang: " + this.total_umfang.toFixed(2))
            console.log("total_auflage: " + this.total_auflage.toLocaleString('de-DE'))
            console.log("reichweite: " + this.reichweite.toLocaleString('de-DE'))

            console.log("gattungen")
            console.log(this.gattungen)

            console.log("produkte")
            console.log(this.produkte)
      */





      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"
      }else{

        if (localStorage.product == "Oknoplast"){

           extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND (lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%')) AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }else{

          extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND lower(projekt) LIKE lower('%" + localStorage.product  + "%') OR lower(untertitel) LIKE lower('%" + localStorage.product  + "%') OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }

      }


      // total_print_umfang_tz
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      console.log(extension)

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.total_print_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')









      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung='Online-Medien' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"
      }else{

        if (localStorage.product == "Oknoplast"){

          extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung='Online-Medien' AND (lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%')) AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }else{

          extension = "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung='Online-Medien' AND lower(projekt) LIKE lower('%" + localStorage.product  + "%') OR lower(untertitel) LIKE lower('%" + localStorage.product  + "%') OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND edatum LIKE '%" + this.id + "%'"

        }

      }


      // total_print_umfang_tz
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      console.log(extension)

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.total_online_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')


    },


    async Accumulation(){

        // Pressejahr wird ausgelesen

      // Einstellungen
      var param = {
        table: "customer",
        statement: "select",
        type: "detail",
        where: "true",
        selector: "username",
        value: localStorage.username,
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      var pressejahr_month_start = result[0]["pressejahr_month_start"]

      var year_start_formated = ""
      var year_end_formated = ""

      if(pressejahr_month_start != ""){

        console.log(this.translateMonthNumber(this.translateMonthEN(pressejahr_month_start)))
        console.log(this.$moment(Date.now()).format('M'))

if(Number(this.translateMonthNumber(this.translateMonthEN(pressejahr_month_start))) < Number(this.$moment(this.id, "MM.YYYY").format('M'))){

  this.year_start = pressejahr_month_start + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')
  this.year_end = this.translateMonth(this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM')) + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')

  year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')
  year_end_formated = this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM') + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')

}else{

if(pressejahr_month_start == "Januar"){
    this.year_start = pressejahr_month_start + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))
    this.year_end = this.translateMonth(this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM')) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))

    year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))
    year_end_formated = this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM') + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))

}else{

    this.year_start = pressejahr_month_start + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)
    this.year_end = this.translateMonth(this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM')) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))

    year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)
    year_end_formated = this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM') + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')))

}


}

      }else{

        pressejahr_month_start = "Januar"

        if(this.translateMonthNumber(this.translateMonthEN(pressejahr_month_start)) > this.$moment(Date.now()).format('M')){
          this.year_start = pressejahr_month_start + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')
          this.year_end = this.translateMonth(this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM')) + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')

          year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')
          year_end_formated = this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM') + " " + this.$moment(this.id, "MM.YYYY").format('YYYY')

        }else{
          this.year_start = pressejahr_month_start + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)
          this.year_end = this.translateMonth(this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM')) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)

          year_start_formated = this.translateMonthEN(pressejahr_month_start) + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)
          year_end_formated = this.$moment(this.id, "MM.YYYY").endOf('month').format('MMMM') + " " + (Number(this.$moment(this.id, "MM.YYYY").format('YYYY')) - 1)

        }

      }


// console.log(year_start_formated)
// console.log(year_end_formated)
// console.log(this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD'))
// console.log(this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD'))


      var extension

      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung != 'Online-Medien' AND  customer_id='" + localStorage.customer_id + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%')) AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "
      }else{

        if (localStorage.product == "Oknoplast"){

          extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung != 'Online-Medien' AND  customer_id='" + localStorage.customer_id + "' AND (lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

        }else{

          extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung != 'Online-Medien' AND ((lower(projekt) LIKE lower('%" + localStorage.product  + "%') OR lower(untertitel) LIKE lower('%" + localStorage.product  + "%') OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' ) AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

        }

      }



      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log("kumuliert")
      console.log(param.extension)
      console.log(result)

      this.kumuliert_print_belege = Number(result[0]["count(*)"]).toLocaleString('de-DE')
      this.kumuliert_print_auflage = Number(result[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE')
      this.kumuliert_print_reichweite = Number(result[0]["sum(reichweite)"]).toLocaleString('de-DE')


      this.kumuliert_print_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')



        this.kumuliert_print_aäwg = Number(result[0]["sum(aäwg)"])

        var test = this.kumuliert_print_auflage.toLocaleString().replace(".", "")
        test = test.replace(".", "")

        this.kumuliert_print_reichweite = Number(Number(test) * 2.5).toFixed(0)
        this.kumuliert_print_reichweite = Number(this.kumuliert_print_reichweite).toLocaleString('de-DE').replace(",", ".")




        if (localStorage.product == "Alle" || localStorage.product == ""){
            extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien' AND  customer_id='" + localStorage.customer_id + "' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%')) AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "
        }else{

            if (localStorage.product == "Oknoplast"){

                extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien' AND  customer_id='" + localStorage.customer_id + "' AND (lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

            }else{

                extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien' AND (lower(projekt) LIKE lower('%" + localStorage.product  + "%') OR lower(untertitel) LIKE lower('%" + localStorage.product  + "%') OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

            }

        }


      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log("Online Medien !!!!")
      console.log(param.extension)
      console.log(result)

      this.kumuliert_online_belege = Number(result[0]["count(*)"]).toLocaleString('de-DE')
      this.kumuliert_online_reichweite = Number(result[0]["sum(reichweite)"]).toLocaleString('de-DE')
      this.kumuliert_online_auflage = Number(result[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toLocaleString('de-DE')

      this.kumuliert_online_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')









      if (localStorage.product == "Alle" || localStorage.product == ""){
        extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien' AND newsletter='1' AND customer_id='" + localStorage.customer_id + "' AND newsletter='1' AND lower(untertitel) NOT LIKE '%infobeleg%' AND (lower(untertitel) NOT LIKE lower('%werbung%') AND lower(projekt) NOT LIKE lower('%werbung%')) AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "
      }else{

        if (localStorage.product == "Oknoplast"){

          extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien' AND newsletter='1' AND  customer_id='" + localStorage.customer_id + "' AND newsletter='1' AND (lower(projekt) NOT LIKE lower('%WnD%') AND lower(projekt) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%WnD%') AND lower(untertitel) NOT LIKE lower('%Aluhaus%') AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

        }else{

          extension = "SELECT count(*), sum(aäwg), sum(reichweite), sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(umfang), sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung = 'Online-Medien'  AND newsletter='1' AND (lower(projekt) LIKE lower('%" + localStorage.product  + "%') OR lower(untertitel) LIKE lower('%" + localStorage.product  + "%') OR lower(marke) LIKE '%" + localStorage.marke.toString().toLowerCase() + "%' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE '%infobeleg%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' "

        }

      }


      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)

      console.log("Newsletter")
      console.log(param.extension)
      console.log(result)


      this.kumuliert_newsletter_belege = Number(result[0]["count(*)"]).toLocaleString('de-DE')







/*
      // Einstellungen
      param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(replace(umfang, ',', '.')) FROM releaselists WHERE gattung!='Online-Medien' AND lower(untertitel) NOT LIKE lower('%werbung%') AND lower(untertitel) NOT LIKE lower('%info%') AND customer_id='" + localStorage.customer_id + "' AND date_timestamp between '" + this.$moment(year_start_formated, "MMMM YYYY").startOf('month').format('YYYY-MM-DD') + " 00:00:00' and '" + this.$moment(year_end_formated, "MMMM YYYY").endOf('month').format('YYYY-MM-DD') + " 23:59:00' ",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      data = {
      }

      result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.kumuliert_print_umfang_tz = Number(result[0]["sum(replace(umfang, ',', '.'))"]).toLocaleString('de-DE')
*/

    },



    translateMonth(englishname){

      var output

      switch(englishname) {

        case "January":
          output = "Januar"
          break;

        case "February":
          output = "Februar"
          break;

        case "March":
          output = "März"
          break;

        case "April":
          output = "April"
          break;

        case "May":
          output = "Mai"
          break;

        case "June":
          output = "Juni"
          break;

        case "July":
          output = "Juli"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "October":
          output ="Oktober"
          break;

        case "November":
          output = "November"
          break;

        case "December":
          output = "Dezember"
          break;
      }


      return output



    },

    translateMonthNumber(englishname){

      var output

      switch(englishname) {

        case "January":
          output = "1"
          break;

        case "February":
          output = "2"
          break;

        case "March":
          output = "3"
          break;

        case "April":
          output = "4"
          break;

        case "May":
          output = "5"
          break;

        case "June":
          output = "6"
          break;

        case "July":
          output = "7"
          break;

        case "August":
          output = "8"
          break;

        case "September":
          output = "9"
          break;

        case "October":
          output ="10"
          break;

        case "November":
          output = "11"
          break;

        case "December":
          output = "12"
          break;
      }


      return output



    },

    translateMonthEN(germanname){

      var output

      switch(germanname) {

        case "Januar":
          output = "January"
          break;

        case "Februar":
          output = "February"
          break;

        case "März":
          output = "March"
          break;

        case "April":
          output = "April"
          break;

        case "Mai":
          output = "May"
          break;

        case "Juni":
          output = "June"
          break;

        case "Juli":
          output = "July"
          break;

        case "August":
          output = "August"
          break;

        case "September":
          output = "September"
          break;

        case "Oktober":
          output ="October"
          break;

        case "November":
          output = "November"
          break;

        case "Dezember":
          output = "December"
          break;
      }


      return output



    },



     compare( a, b ) {
        if ( a.name < b.name ){
        return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
     }




  },




}
</script>

<style scoped>

</style>
