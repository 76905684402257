<template>

    <v-navigation-drawer
        v-model="drawer"
        app
        :permanent="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
        :disable-resize-watcher="false"
        clipped
        expand-on-hover
    >

      <v-divider></v-divider>

      <v-list
          dense
          nav
      >

        <v-list-item
            :id="'menu_' + item.name.toString().trim()"

            v-for="item in items"
            :key="item.title"
            link
            @click="goto(item.link)"

        >

          <v-list-item-icon

          >
            <v-icon
                :id="'icon_' + item.name.toString().trim()"
            >{{ item.icon }}</v-icon>
          </v-list-item-icon>



          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
<!--
          </router-link>
-->
        </v-list-item>

<!--
        </v-list-item-group>
-->
      </v-list>
    </v-navigation-drawer>

</template>

<script>


export default {
  name: "Sidebar",

  data () {
    return {
      // https://pictogrammers.github.io/@mdi/font/5.4.55/
      // mini: true,
      drawer: true,

      items: [],
      selectedColor: '#CA0931',
      defaultColor: 'rgba(0, 0, 0, 0.54)',
      right: null,
    }
  },

  created(){



    if(localStorage.typ == "admin"){
      this.items.push({ name: 'kunden', title: ' Kunden', icon: 'mdi-account', link: '/ListCustomer' })
      this.items.push({ name: 'produkte', title: ' Produkte/Projekte', icon: 'mdi-head-lightbulb-outline', link: '/ListProject' })
      this.items.push({ name: 'releaselists', title: ' Veröffentlichungen', icon: 'mdi-newspaper-variant-outline', link: '/ListRelease' })
      this.items.push({ name: 'city', title: ' Städte', icon: 'mdi-city', link: '/ListCity' })
      this.items.push({ name: 'medium', title: ' Medien', icon: 'mdi-newspaper-variant-multiple-outline', link: '/ListMedium' })
      this.items.push({ name: 'upload', title: ' Import', icon: 'mdi-upload', link: '/Upload' })
    }else{
      this.items.push({ name: 'dashboard', title: ' Dashboard', icon: 'mdi-view-dashboard', link: '/Dashboard'  })
      this.items.push({ name: 'releaselists', title: ' ' + this.$t('releaselists'), icon: 'mdi-newspaper-variant-outline', link: '/ReleaseLists' })


      this.items.push({ name: 'research', title: ' ' + this.$t('research'), icon: 'mdi-magnify', link: '/Recherche' })
      this.items.push({ name: 'topprojects', title: ' ' + this.$t('topprojects'), icon: 'mdi-chart-box-outline', link: '/TopProjects' })

      this.items.push({ name: 'projects', title: ' ' + this.$t('projects'), icon: 'mdi-folder-multiple', link: '/ListProject' })



      /*
      this.items.push({ name: 'facebook', title: ' Facebook Auswertungen', icon: 'mdi-facebook', link: '/Facebook' })
      this.items.push({ name: 'planer', title: ' Redaktionsplan', icon: 'mdi-format-list-text', link: '/Planer' })
      this.items.push({ name: 'calendar', title: ' Event Kalender', icon: 'mdi-calendar-month', link: '/Calendar' })
      */

      this.items.push({ name: 'advertisment', title: ' ' + this.$t('advertisment'), icon: 'mdi-advertisements', link: '/Ads' })
      this.items.push({ name: 'infobelege', title: ' ' + this.$t('infobelege'), icon: 'mdi-head-lightbulb-outline', link: '/Infoclip' })

    }


    if(localStorage.username == "sommer" ){
      this.items = []
      this.items.push({ name: 'infobelege', title: ' ' + this.$t('infobelege'), icon: 'mdi-head-lightbulb-outline', link: '/Infoclip' })
    }


    if(localStorage.username == "bona" ){
      this.items = []
      this.items.push({ name: 'infobelege', title: ' ' + this.$t('infobelege'), icon: 'mdi-head-lightbulb-outline', link: '/Infoclip' })
    }


  },


  mounted(){

    this.setSidebar();

  },

  watch:{
    $route (){
      this.setSidebar();
    },

  },

  methods:{


    goto(path){
      if("/" + this.$route.name != path){
        this.$router.push(path)
      }
    },


    setSidebar(){

      console.log("setSidebar: " + this.$route.name)

      let i = 0;

      while (this.items[i]) {

        if(this.$route.name == this.items[i].link.toString().replace("/", "")){
          document.getElementById("menu_" + this.items[i].name).style.color = this.selectedColor;
          document.getElementById("icon_" + this.items[i].name).style.color = this.selectedColor;

          this.title = "-" + this.items[i].title
        }else{
          document.getElementById("menu_" + this.items[i].name).style.color = this.defaultColor;
          document.getElementById("icon_" + this.items[i].name).style.color = this.defaultColor;
        }

        i++;
      }


    }

  },

}
</script>

<style scoped>
.highlighted{
 color: red;
}

</style>
