<template>
  <v-container

      fluid>

    <div>
      <!-- :custom-sort="customSort" -->

      <v-data-table
          :headers="headers"
          :items="data"
          item-key="id"
          :items-per-page="100"
          class="elevation-7"
          :search="search"
          :custom-filter="filterOnlyCapsText"

          :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
           'items-per-page-options': [50, 100, 200, -1],
           'items-per-page-text':'pro Seite'
      }"


      >

          <template v-slot:item.aäwg="{item}">

              <p v-if="item.aäwg == ''">k.A.</p>
              <p v-else>{{ Number(item.aäwg).toLocaleString('de-DE').replace(",", ".") }} EUR</p>

          </template>


          <template v-slot:item.auflage="{item}">

              <p>{{ Number(item.auflage).toLocaleString('de-DE').replace(",", ".") }}</p>

          </template>


        <template v-slot:item.note="{item}">

        <p>{{ item.note.toString().substring(0, 15) }}</p>

        </template>


        <template v-slot:item.projekt="{item}">

        <p style="color: red" v-if='item.untertitel == "infobeleg" || item.untertitel == "Infobeleg"'>INFOBELEG</p>
        <p style="color: red" v-if='item.untertitel == "werbung" || item.untertitel == "Werbung"'>WERBUNG</p>
        <p v-else>{{ item.projekt }}</p>

        </template>

        <template v-slot:item.weight="{item}">

          <p style="color: green" v-if="Number(item.weight) >= 0">{{ item.weight }}</p>
          <p style="color: red" v-else>{{ item.weight }}</p>

        </template>


        <template v-slot:item.projectstart="{item}">

          <p> {{ item.project_start_month }} / {{ item.project_start_year }} </p>

        </template>



        <template v-slot:item.erfassung="{item}">

          <p> {{ formatDate(item.erfassung) }} </p>

        </template>

        <template v-slot:item.invoice="{item}">

          <p style="color: green" v-if="item.invoice == 1"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>

        <template v-slot:item.invoice_payment_date="{item}">

          <p style="color: green" v-if="item.invoice_payment_date != ''"><v-icon color="green" style="margin-top: 5px;">mdi-check-circle</v-icon></p>
          <p style="color: red" v-else><v-icon color="red" style="margin-top: 5px;">mdi-close-circle</v-icon></p>

        </template>

        <template v-slot:item.typ="{item}">

          <p style="color: green" v-if="item.typ == 'buy'">Einkauf</p>
          <p style="color: red" v-if="item.typ == 'sell'">Verkauf</p>
          <p style="color: red" v-if="item.typ == 'disposal'">Verschrottung</p>

        </template>

        <template v-slot:item.prio="{item}">

          <p style="color: green" v-if="item.prio == 1">&blk34;</p>
          <p style="color: orange" v-if="item.prio == 2">&blk34;</p>
          <p style="color: red" v-if="item.prio == 3">&blk34;</p>

        </template>


        <template v-slot:top>
          <v-text-field
              v-model="search"
              label="Suche / Filter"
              clearable
              class="mx-4"
          ></v-text-field>
        </template>

        Zeitung, Regionalausgabe


        <template v-slot:item.gattung="{item}">

          <v-row
              v-if="item.gattung == 'Zeitung, Regionalausgabe' || item.gattung == 'Zeitungen' || item.gattung == 'Tageszeitung' || item.gattung == 'Zeitung' "
          >
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
          Tageszeitungen

            </div>

          </v-row>

          <v-row
              v-else
          >
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              {{item.gattung}}

            </div>

          </v-row>


        </template>





        <template v-slot:item.show_belege="{item}">

          <v-row
              v-if="item.images == 'true'"
          >
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon @click="showBeleg(item.id)" style="padding: 3px;">mdi-information-outline</v-icon>

              <v-icon v-if="item.newsletter == '1'"  style="color: #CA0931; padding: 3px;">mdi-email-newsletter</v-icon>
            </div>






          </v-row>

        </template>





        <template v-slot:item.belege="{item}">

          <v-row
          v-if="item.images == 'true'"
          >
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon style="padding: 3px;" color="green">mdi-check-bold</v-icon>

            </div>

          </v-row>

          <v-row
              v-if="item.images == 'false'"
          >
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon style="padding: 3px;" color="red">mdi-close-thick</v-icon>

            </div>

          </v-row>

        </template>

        <template v-slot:item.delete="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
              <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id, item.company)">mdi-delete-outline</v-icon>

            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>

        <template v-slot:item.edit="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
          <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id, item.company)">mdi-delete-outline</v-icon>

          <v-icon style="padding: 3px;" @click="editEntry(item.id)">mdi-pencil-outline</v-icon> <!--{{item.calories}}-->
            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>

        <template v-slot:item.edit_login="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
          <v-icon style="padding: 3px;" color="red" @click="showDeleteDialog(item.id, item.company)">mdi-delete-outline</v-icon>

          <v-icon style="padding: 3px;" @click="editEntry(item.id)">mdi-pencil-outline</v-icon> <!--{{item.calories}}-->

          <v-icon style="padding: 3px;" @click="testLogin(item.id)">mdi-login-variant</v-icon> <!--{{item.calories}}-->



            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>

        <template v-slot:item.select_add="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >
               <v-icon color="pink" style="padding: 3px;" @click="add2Tour(item.id)">mdi-plus-box</v-icon> <!--{{item.calories}}-->
            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>

        <template v-slot:item.select_delete="{item}">

          <v-row>
            <div
                style="
              display: inline;
              white-space: nowrap;"
            >

              <v-icon color="gray" style="padding: 3px;" @click="posUp(item.id)">mdi-arrow-up-drop-circle</v-icon> <!--{{item.calories}}-->
              <v-icon color="gray" style="padding: 3px;" @click="posDown(item.id)">mdi-arrow-down-drop-circle</v-icon> <!--{{item.calories}}-->


              <v-icon color="#383083" style="padding: 3px;" @click="delete2Tour(item.id)">mdi-minus-box</v-icon> <!--{{item.calories}}-->


            </div>

          </v-row>
          <!--
          <v-hover v-slot:default="{ hover }">
            <v-badge id="badge" :class="{'on-hover':hover}" color="deep-purple accent-4" content="Second Button" left transition="slide-x-transition">
            </v-badge>
          </v-hover>
          -->
        </template>



        <!--

        <template v-slot:body.append>
          <tr>
            <td></td>
            <td>
              <v-text-field
                  v-model="calories"
                  type="number"
                  label="Weniger als"
              ></v-text-field>
            </td>
            <td colspan="4"></td>
          </tr>
        </template>

        -->
      </v-data-table>
    </div>

    <!--
    LÖSCHEN DIALOG
    -->

    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="600px"
      >

<!--
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Open Dialog
          </v-btn>
        </template>
-->



        <v-card>
          <v-card-title>
            <span class="text-h5">Wirklich Löschen</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                      Wollen Sie den Datensatz&nbsp;<b>{{dialog_text}}</b>&nbsp;wirklich löschen?
              </v-row>

              <v-text-field
                  v-model="dialog_password"
              type="password"
              placeholder="Passwort"
              ></v-text-field>

            </v-container>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn

                text
                @click="dialog = false"
            >
              Abbrechen
            </v-btn>
            <v-btn
                color="red"
                text
                @click="deleteEntry"
            >
              Löschen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>





    <v-fab-transition>
      <v-btn
          v-if='addbutton == true'
          color="pink"
          dark
          fixed
          bottom
          right
          fab
          @click="openAddView"

      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>




    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        :color="snackbar_color"

    >
      {{ snackbar_title }}
      <!--
            <template v-slot:action="{ attrs }">
              <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
              >
                Close
              </v-btn>
            </template>
       -->

    </v-snackbar>



  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "ListDefault",
  data () {
    return {

      data: [],
      dialog: false,

      search: '',
      sort: '',
      limit: '',

      sql_filter: '',


      umfang: 0,
      auflage: 0,


      dialog_id: '',
      dialog_text: '',
      dialog_password: '',

      snackbar: false,
      snackbar_title: 'Gespeichert!',
      snackbar_color: 'red',
      timeout: 4000,
    }
  },


  created() {

    this.filterLoadData(this.search)

    /*
    window.setInterval(() => {
      this.filterLoadData(this.search)
    }, 25000); // interval set to one sec.
    */

  },

  watch: {

    search(){
      console.log("SEARCH: " + this.search)
      this.filterLoadData(this.search)
    }



  },


  methods: {

    formatDate(date) {
      if (date == null) {
        return "";
      }

      return date.split('-').reverse().join('.');
    },



   async testLogin(n){

  // Einstellungen
  var param = {
    table: "customer",
    statement: "select",
    type: "detail",
    where: "true",
    selector: "id",
    value: n,
    short: "true", // Übermittlung nur von id
    // filter: "false",
    // filterfield: "",
    // filtervalue: "",
    // operator: "true",
    // Filter noch hinzufügen
    extension: "",
  }

  // Daten -> Werden nur bei Insert oder Update gesendet
  var data = {
  }

  var result = await this.$func.queryAPI(param, data)


     localStorage.username = result[0]["username"]
     localStorage.typ =  result[0]["name"]
     localStorage.customer_id = result[0]["id"]
     localStorage.customer_name = result[0]["name"]
     localStorage.product = ""

     this.$router.push({ name: 'Dashboard' })

     location.reload()

   },



    async filterLoadData(search){

      var extension = this.extension


      var sort = " "
      if(this.default_sort != "" || this.sort != ""){
        if(this.sort != ""){
          sort = "ORDER BY `" + this.sort + "` " + this.default_sort_typ + " "
        }else{
          sort = "ORDER BY `" + this.default_sort + "` " + this.default_sort_typ + " "
        }
      }

      var limit = " "
      if(this.default_limit != ""){
        limit = "LIMIT " + this.default_limit
      }



      if(search != ""){

      var query = ""

      let i = 0;
      while (this.headers[i]) {

        if(this.headers[i].value != "erfassung" && this.headers[i].value != "projectstart" && this.headers[i].value != "edit" && this.headers[i].value != "belege" && this.headers[i].value != "show_belege"){
          query = query + this.headers[i].value + " LIKE '%" + search + "%' OR "
        }

        i++;
      }

      query = query.substring(0, query.length - 4)

      if(extension == ""){
        extension = "WHERE (" + query + ") " + sort + limit
      }else{
        extension = "WHERE " + extension + " AND (" + query + ") " + sort + limit
      }

      }else{


        if (extension == ""){
          extension = sort + limit
        }else{
          extension = "WHERE " + extension + " " + sort + limit
        }

      }

      this.sql_filter = extension

      console.log("extension")
      console.log(extension)

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.data = result

      if(this.table == "releaselists"){
        this.calcSum()
      }


    },



    async calcSum(){
     console.log("calcSum")

      // Einstellungen
      var param = {
        table: "releaselists",
        statement: "custom",
        type: "",
        where: "",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "SELECT sum(REPLACE(REPLACE(auflage,'.',''), ',','')), sum(REPLACE(umfang,',','.')) FROM releaselists " + this.sql_filter,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)


      this.umfang = Number(result[0]["sum(REPLACE(umfang,',','.'))"]).toFixed(0)
      this.auflage = Number(result[0]["sum(REPLACE(REPLACE(auflage,'.',''), ',',''))"]).toFixed(0)

      this.$emit('setsum', this.umfang, this.auflage)

    },







    filterOnlyCapsText (value, search) {

      console.log("filter")


      return value != null &
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
    },

    customSort(items, index, isDesc) {
      console.log("customSort")
      console.log(items)
      console.log(index)
      console.log(isDesc)


      if (index[0] === "date") {
        console.log("customSort -> date")
      }


      /*
      items.sort((a, b) => {
        if (index === "date") {
          if (!isDesc) {
            return compare(a.date, b.date);
          } else {
            return compare(b.date, a.date);
          }
        }
      });

       */

      return items;

    },



    openAddView(){
      this.$router.push({ path: this.formview })
    },

    async deleteEntry(){

      if(this.dialog_password != Vue.prototype.$password){

        this.snackbar_title = "Password Falsch!"
        this.snackbar_color = "red"
        this.snackbar = true
        return
      }




      // Einstellungen
      var param = {
        table: this.table,
        statement: "delete",
        type: "detail",
        where: "true",
        selector: "id",
        value: this.dialog_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(result)

      this.dialog = false


      if(this.table == "tour"){
        // Lösche Tour zuordnung
        await this.deleteMatchTour2Task(this.dialog_id)
      }


      this.filterLoadData(this.search)

      this.$emit('reload', '')


    },

    async deleteMatchTour2Task(id){

      // Parameter
      var param = {
        table: "task",
        statement: "update",
        type: "",
        where: "false",
        selector: "",
        value: "",
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "WHERE tour_id='" + id + "' AND pickup_date IS NULL",
      }

      // Data
      var data = {
        tour_id: "",
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },

    async posUp(id){
      console.log("posUp")


      var currentPosition = await this.checkCurrentPosition(id)
      var numofposition = await this.checkNumofPosition()

      console.log("Aktuelle Position: " + currentPosition)
      console.log("ALLE Position: " + numofposition)

      if(currentPosition == 1){
        console.log("Bricht ab")
        return
      }

      await this.changePosition(Number(currentPosition) - 1, Number(currentPosition))
      await this.changePositionByID(id, Number(currentPosition) - 1)


      await this.filterLoadData(this.search)

      this.$emit('reload', '')

    },

    async posDown(id){
      console.log("posDown")

      var currentPosition = await this.checkCurrentPosition(id)
      var numofposition = await this.checkNumofPosition()

      console.log("Aktuelle Position: " + currentPosition)
      console.log("ALLE Position: " + numofposition)

      if(currentPosition == numofposition){
        console.log("Bricht ab")
        return
      }

      await this.changePosition(Number(currentPosition) + 1, Number(currentPosition))
      await this.changePositionByID(id, Number(currentPosition) + 1)

      await this.filterLoadData(this.search)

      this.$emit('reload', '')

    },

    async resortPosition(){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "list",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "ORDER BY `tour_position` ASC",
      }

      // Data
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      if(result.length == 0){
        return
      }

      for (var i1 = 0; i1 < result.length; i1++) {
        await this.changePositionByID(result[i1]["id"], i1 + 1)
      }


      console.log(result)

    },

    async changePosition(pos_old, pos_new){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "AND tour_position='" + pos_old + "'",
      }

      // Data
      var data = {
        tour_position: pos_new,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },

    async changePositionByID(id, pos_new){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {
        tour_position: pos_new,
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

    },

  // Liefert Anzahl der Positionen
   async checkNumofPosition(){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "detail",
        where: "true",
        selector: "temp_tour_id",
        value: this.temp_tour_id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

       // Data
       var data = {
       }

       var result = await this.$func.queryAPI(param, data)
       console.log(result)

       return result.length ?? 0

    },

   async checkCurrentPosition(id){

      // Parameter
      var param = {
        table: 'task',
        statement: 'select',
        type: "detail",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Data
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      return result[0]["tour_position"]

    },

   async add2Tour(id){
      // temp_tour_id

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        temp_tour_id: this.temp_tour_id,
        tour_position: Number(await this.checkNumofPosition()) + 1
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

     await this.filterLoadData(this.search)

      // Löst ein Reload aus ggf. andere List
      this.$emit('reload', '')


    },

   async delete2Tour(id){

      // Parameter
      var param = {
        table: 'task',
        statement: 'update',
        type: "",
        where: "true",
        selector: "id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }


      // Data
      var data = {
        temp_tour_id: "",
        tour_id: "",
      }

      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      await this.resortPosition()

     await this.filterLoadData(this.search)

      // Löst ein Reload aus ggf. andere List
      this.$emit('reload', '')


    },

    showDeleteDialog(id, text){
      this.dialog_id = id
      this.dialog_text = text
      this.dialog = true
    },



    async showBeleg(id){


      // Einstellungen
      var param = {
        table: "images",
        statement: "select",
        type: "list",
        where: "true",
        selector: "releaselists_id",
        value: id,
        short: "true", // Übermittlung nur von id
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: "",
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      console.log("image")
      console.log(id)
      var result = await this.$func.queryAPI(param, data)
      console.log(result)

      // this.image_url = Vue.prototype.$api_upload + result[0]["name"]

      if(result[0]["type"] == "pdf"){
        window.open(Vue.prototype.$api_upload + result[0]["name"])
        return
      }


      this.image_url = []

      for(var i = 0; i < result.length; i++){
        this.image_url.push(Vue.prototype.$api_upload + result[i]["name"])
      }

      const preview = this.$imagePreview({
        initIndex:0,
        isEnableBlurBackground: false,
        images: this.image_url,
      })

      console.log(preview)

      // this.overlay = true

    },







    editEntry(id){

      // this.$router.push({ path: this.formview + "/" + id, params:{id: id} })

      const routeData = this.$router.resolve({name: this.formview, params:{id: id}});
      window.open(routeData.href, '_blank');

    },

    /*
    async loadData(){

      this.data = []

      // Einstellungen
      var param = {
        table: this.table,
        statement: "select",
        type: "list",
        where: "false",
        selector: "",
        value: "",
        short: "false", // Übermittlung nur von id
        debug: "false",
        // filter: "false",
        // filterfield: "",
        // filtervalue: "",
        // operator: "true",
        // Filter noch hinzufügen
        extension: this.extension,
      }

      // Daten -> Werden nur bei Insert oder Update gesendet
      var data = {
      }

      var result = await this.$func.queryAPI(param, data)

      console.log(this.extension)

      console.log("loadData ---!")
      console.log(result)

      this.data = result

    },
    */

  },

  props: {

    headers: Array,
    formview: String,
    table: String,
    extension: String,

    default_limit: {
      default: "1000",
      type: String
    },

    default_sort: {
      default: "",
      type: String
    },

    default_sort_typ: {
      default: "DESC",
      type: String
    },

    addbutton: {
      default: true,
      type: Boolean
    },


    temp_tour_id: String,

  }



}
</script>

<style scoped>

</style>
