/* Kundenbereich - Recherche */

<template>
  <div>
    <div
    style="
    position: absolute;
    top: 15px;
    left: 70px;
    "
    >{{$t("auflage")}}: {{auflage}}<div style="margin: 10px;"></div> {{$t("umfang")}}: {{umfang}}</div>


    <List

        style="margin-top: 60px;"
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"
        v-bind:addbutton="true"
        v-bind:default_limit="'500'"
        v-bind:default_sort="'date_timestamp'"
        v-bind:default_sort_typ="'DESC'"
        v-bind:extension="extension"
        @setsum="setSum"
    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "Reserach",

  components:{
    List,
  },

  data: () => ({
    formview: 'FormRelease',
    table: 'releaselists',

    umfang: '0',
    auflage: '0',

    extension: '',
    List: [],
  }),


  created() {


    this.extension = "kunde='" + localStorage.customer_name + "'"

  },


  methods:{

    setSum(a, b){

      this.umfang = a
      this.auflage = Number(b).toLocaleString('de-DE')

    }

  },


  computed: {
    headers () {
      return [
        { text: this.$t("termin"), align: 'start', sortable: false, value: 'edatum' },
        { text: this.$t("erfassung"), value: 'erfassung' },
        { text: this.$t("medium"), value: 'medium' },
        { text: this.$t("ort"), value: 'ort' },
        { text: this.$t("marke"), value: 'marke' },
        { text: this.$t("projectstart"), value: 'projectstart' },
        { text: this.$t("produkteprojekte"), value: 'projekt' },

        { text: this.$t("umfang"), value: 'umfang' },
        { text: this.$t("auflage"), value: 'auflage' },
        { text: this.$t("aquivalenzwert"), value: 'aäwg' },

        { text: this.$t("gattung"), value: 'gattung' },
        { text: this.$t("beleg"), value: 'show_belege' },
        { text: '', value: 'edit' },

      ]
    },
  },


}
</script>

<style scoped>

</style>
