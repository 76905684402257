<template>
  <div>

    <List
        v-bind:headers="headers"
        v-bind:formview="formview"
        v-bind:table="table"
        v-bind:addbutton="true"
        v-bind:default_limit="'1000'"
        v-bind:default_sort="'name'"
        v-bind:default_sort_typ="'ASC'"
        v-bind:extension="extension"
    ></List>

  </div>
</template>

<script>

import List from '@/components/ListDefault'

export default {
  name: "ListProject",

  components: {
    List,
  },

  created() {

    if (localStorage.typ == "admin") {
      this.extension = ""
    } else {
      this.extension = "customer_name='" + localStorage.customer_name + "'"
    }

  },

  mounted() {

  },

  data () {
    return {
      search: '',
      formview: 'FormProject',
      table: 'project',
      extension: '',
    }
  },

  computed: {
    headers () {
      return [
        { text: 'Projekt', align: 'start', sortable: true, value: 'name' },
        { text: 'Firma', value: 'customer_name' },
        { text: 'Marke', value: 'marke' },
        { text: 'Start Monat', value: 'start_month' },
        { text: 'Start Jahr', value: 'start_year' },
        { text: '', value: 'edit' },
      ]
    },
  },

}
</script>

<style scoped>

</style>
